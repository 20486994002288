#root{
  width: 90%;
  height: 100vh;
}
.downloadBox {
  width: 100%;
  height: 100%;
}
.browser1 {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(./assest/browser/browser1.png);
  background-size: 100% 100%;

  padding: 0 5.06vw;
  
  
}
.browser1 .logo {
  width: 32.5vw;
  margin: 0 auto;
  padding-top: 36.53vw;
 
}
.browser1 .logo .logoImg {
  width: 19.6vw;
  height: 19.46vw;
  margin: 0 auto;
  background: url(./assest/browser/logo.png);
  background-size: 100% 100%;
}
.browser1 .logo h3 {
  font-size: 6.248vw;
  font-family: HuXiaoBo-NanShen;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-top: 5.33vw;
}
.browser1 .buttonList {
  width: 68vw;
  margin: 35.6vw auto 0 auto;
  
}
.browser1 .android {
  width: 68vw;
  background: url(./assest/browser/Android.png);
  background-size: 100% 100%;
  height: 14vw;
  border: none;
}
.browser1 .ios {
  width: 68vw;
  background: url(./assest/browser/ios.png);
  background-size: 100% 100%;
  height: 14vw;
  margin-top: 5.2vw;
  border: none;
}
.browser {
  position: absolute; /* 设置为绝对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
 
}
.browser .line {
  width: 16vw;
  height: 45.09vw;
  background: url(./assest/browser/line.png);
  background-size: 100% 100%;
  margin-left: 76vw;
}
.browser .contentTitle {
  letter-spacing: 2px;
  width: 88.66vw;
  margin: 0 auto;
  color: #ffffff;
  font-size: 4.8vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-family: PingFang-SC-Bold;

  
}
.browser .contentTitle .icon1 {
  width: 9.73vw;
  height: 4.93vw;
  background: url(./assest/browser/icon1.png);
  background-size: 100% 100%;
  margin: 0 1.5px;
}
.browser .contentTitle .icon2 {
  width: 7.6vw;
  height: 7.6vw;
  background: url(./assest/browser/icon2.png);
  background-size: 100% 100%;
  margin-left: 0.8vw;
}
